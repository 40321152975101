import { graphql } from "gatsby"
import { HomePage } from "@pageTemplates"

export default HomePage

export const pageQuery = graphql`
  query Root {
    homeJson {
      fields {
        slug
      }
      carouselImages {
        image {
          src {
            childImageSharp {
              gatsbyImageData(width: 2000)
            }
            publicURL
          }
          alt
        }
        text
        textLocation
      }
      title
      intro
      callToAction {
        content
        linkLocation
      }
      linkCards {
        title
        cards {
          image {
            src {
              childImageSharp {
                gatsbyImageData(width: 600)
              }
            }
            alt
          }
          linkLocation
          linkText
        }
      }
      charitiesCarousel {
        image {
          src {
            childImageSharp {
              gatsbyImageData(height: 128, placeholder: TRACED_SVG)
            }
          }
          alt
        }
      }
      contactUsButton {
        content
        linkLocation
      }
    }
  }
`
